//app-navigation-menu,
:root .side-menu {
  background-color: var(--mat-sys-surface-container-low);
  box-shadow: var(--mat-sys-level2);
}

.mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  padding-right: 0.25rem;
  overscroll-behavior-y: contain;
  scrollbar-gutter: stable;
}

.mat-drawer-content {
  min-height: 100vh;
}

:root .mdc-list {
  padding: 0;
}

hr {
  margin: 16px 0;
}

.drawer-footer {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: auto;
  padding-top: 1rem;
}

@media (min-width: 992px) {
  .drawer-footer {
    justify-content: normal;
  }
}
